/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import { TreeMapComponent, Inject, TreeMapTooltip } from '@syncfusion/ej2-react-treemap';
import './sa-cohort.styles.scss';
import { connect, useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { setSelectedSampleAttributeSet, setSampleAttributeState } from '../../../redux/collections/collections.actions';
import { getSelectedSampleAttributeValues } from '../../../redux/collections/collections.selectors';
import { colorCodes } from '../../../core/utils/colors';
import AppService from '../../services/app-service';
import { setLoader } from '../../../redux/app/app.actions';
import { SAMPLE_ORDER_DATA } from './sa-cohort.data';
import { setIsSampleBlockClicked, setModalData, setNewCohortDimension } from '../../../redux/modal/modal.actions';
import { getNewCohortDimension } from '../../../redux/modal/modal.selectors';

const SampleAttributesCohort = (props) => {
    let { selectedSampleAttributeSet } = props;
    const {
        dataId,
        newSampleAttribute,
        setLoader,
        setSelectedSampleAttributeSet,
        onItemClick,
        onDeleteItem,
        setSampleAttributeState,
        sampleAttributeData,
        canClick = true,
        canScaleUp = true,
        canDelete = true,
        sampleAttributeDataReset = false,
        displayEntireSamples = false,
        enableOrderSuffix = true,
        className = '',
        dimensions = '175px',
        gap = 0,
        viewSingleBlock,
        enableSampleOrdering = true,
        sampleListingOrder = []
    } = props;
    const [sampleAttributeList, setSampleAttributeList] = useState([]);
    const [optionalAtttibutesIndices, setOptionalAttributeIndices, optionalAtttibutesIndicesRef] = useState([]);
    const toolTipFormat = ' ${displayName} (n=${count}) ';
    const weightValuePath = 'count';
    const equalColorValuePath = 'name';
    const leafItemSettings = {
        showLabels: false,
        labelPath: 'name',
        labelPosition: 'Center',
        gap
    };
    let dimension = useSelector(getNewCohortDimension);
    let dispatch = useDispatch();

    useEffect(() => {
        if (sampleAttributeData) { processSampleAttributesForSync(); }
        return () => {
            dispatch(setIsSampleBlockClicked(false));
        }
    }, [sampleAttributeData]);

    useEffect(() => {
        if (dimension) {
            viewSingleSampleBlock(dimension);
        }
        return () => {
            dispatch(setNewCohortDimension(null));
        }
    }, [dimension]);

    useEffect(() => {
        if (sampleAttributeDataReset) {
            selectedSampleAttributeSet = [];
            setSelectedSampleAttributeSet(null);
        }
    }, [sampleAttributeDataReset]);

    useEffect(() => {
        if (selectedSampleAttributeSet && selectedSampleAttributeSet.length && canClick) {
            const filterData = {
                id: dataId,
                filters: selectedSampleAttributeSet
            };
            onItemClick(filterData);
        }
        return () => { selectedSampleAttributeSet = []; }
    }, [selectedSampleAttributeSet]);

    useEffect(() => {
        if (newSampleAttribute && newSampleAttribute.value) { processSampleAttributesForSync(); }
    }, [newSampleAttribute]);

    const processSampleAttributesForSync = () => {
        let sampleAttributeArrayList = []; // [...sampleAttributeList];
        let sampleAttributeArrayListCopy = [];
        setSampleAttributeState([]);
        if (newSampleAttribute) { // && sampleAttributeList.length
            sampleAttributeList.map((obj, i) => (obj['id'] = i + 1));
            const newAttribute = attributeListProcess(newSampleAttribute, sampleAttributeList.length + 1);
            sampleAttributeArrayListCopy = [...sampleAttributeList, newAttribute]; // []; // [...sampleAttributeList];
            sampleAttributeArrayList = reorderNewSampleAttributes(sampleAttributeArrayListCopy);
            sampleAttributeArrayList.map((obj, i) => (obj['id'] = i + 1));
            setTimeout(() => {
                setSampleAttributeState(sampleAttributeArrayList);
                setSampleAttributeList(sampleAttributeArrayList);
                setLoader(false);
            }, 500);
        } else { // setSampleAttributeList(sampleAttributeArrayList);
            if (sampleAttributeData) { // if (sampleAttributes) {
                const optionalAttributes = dataToArrayConversion(sampleAttributeData.optionalAttributes, 'OPTIONAL');
                const sampleAttributes = dataToArrayConversion(sampleAttributeData.sampleAttributes, 'NORMAL');
                sampleAttributeArrayList = reorderNewSampleAttributes([...sampleAttributes, ...optionalAttributes]);
                if (sampleAttributeArrayList.length) {
                    sampleAttributeArrayList.map((obj, i) => {
                        obj['id'] = i + 1;
                        if (obj.leafItemSettings.colorMapping.length === 1 && obj.leafItemSettings.colorMapping[0]['value'] !== 'NA') {
                            obj.leafItemSettings.colorMapping[0].color = colorCodes[i];
                        }
                        return obj;
                    });
                    setSampleAttributeState(sampleAttributeArrayList);
                    setSampleAttributeList(sampleAttributeArrayList);
                }
            }
        }
    };

    const reorderNewSampleAttributes = (attributeList = []) => {
        let attributesObj = {};
        let attributesArray = [];
        let orderList = SAMPLE_ORDER_DATA;
        let endsWith = (str, suffix) => {
            return (str.indexOf(suffix, str.length - suffix.length) !== -1);
        }
        if (attributeList.length) {
            attributeList.forEach(attribute => {
                if (attribute.attributeName) {
                    attributesObj[attribute.attributeName] = attribute;
                } else {
                    attributesObj[attribute.name] = attribute;
                }
            });
            // ORDERING SAMPLES BASED ON MUTATION ATTRIBUTES BASED ON PREDEFINED SAMPLE ORDER--------------
            if (enableSampleOrdering && !displayEntireSamples) {
                orderList.forEach(orderData => {
                    if (!orderData.hasType) {
                        orderData.ORDER_LIST.forEach(order => {
                            let orderKey = order;
                            if (enableOrderSuffix) {
                                orderKey = AppService.convertNameWithChar(order, '_');
                            }
                            if (attributesObj[orderKey]) {
                                attributesArray.push(attributesObj[orderKey]);
                            }
                        });
                    } else if (orderData.hasType) {
                        orderData.ORDER_LIST.forEach(order => {
                            let orderKey = order;
                            if (enableOrderSuffix) {
                                orderKey = AppService.convertNameWithChar(order, '_');
                            }
                            Object.keys(attributesObj).forEach(key => {
                                if (endsWith(key.toString(), orderKey)) {
                                    attributesArray.push(attributesObj[key]);
                                }
                            });
                        });
                    }
                });
                return attributesArray;
            }
            // ---------------------------------------------------------------------------------------------
            // ORDERING SAMPLES BASED ON THE ORDER LIST PROVIDED AS PROPS ----------------------------------
            if (sampleListingOrder && sampleListingOrder.length) {
                sampleListingOrder.forEach(order => {
                    let orderKey = order;
                    if (enableOrderSuffix) {
                        orderKey = AppService.convertNameWithChar(order, '_');
                    }
                    if (attributesObj[orderKey]) {
                        attributesArray.push(attributesObj[orderKey]);
                    }
                });
                Object.keys(attributesObj).forEach(key => {
                    if (attributesArray.findIndex(attribute => (attribute.name === key)) < 0) {
                        attributesArray.push(attributesObj[key]);
                    }
                });
                return attributesArray;
            }
            // ---------------------------------------------------------------------------------------------
        }
        return attributeList;
    }

    const dataToArrayConversion = (attributes, type) => {
        let sampleAttributeArrayList = [];
        let orderedAttributes = [];
        let attributeList = [];
        let countList = [];
        let countListIndexes = [];
        if (attributes) {
            attributeList = AppService.getConvertedArrayList(attributes);
            Object.keys(attributes).forEach((key) => {
                if (attributes[key]) {
                    countList = [...countList, attributes[key].length];
                }
            });
            countList.forEach((val, index) => {
                if (index === 0) {
                    if (type !== 'OPTIONAL') {
                        const optionalIndices = optionalAtttibutesIndicesRef.current;
                        countListIndexes.push(optionalIndices[optionalIndices.length - 1]);
                    } else {
                        countListIndexes.push(0);
                    }
                } else {
                    let indexVal = countListIndexes[index - 1] ? countListIndexes[index - 1] : countList[index - 1];
                    countListIndexes.push(val + indexVal);
                }
            });
            if (type === 'OPTIONAL') {
                setOptionalAttributeIndices(countListIndexes);
            }
            Object.keys(attributes).forEach((key, index) => {
                if (
                    attributes[key] &&
                    attributes[key][0] &&
                    (typeof attributes[key][0]['name'] !== 'undefined' && attributes[key][0]['name'] !== null)
                ) {
                    const dataObject = {
                        id: index + 1,
                        generatedId: AppService.getRandomId(7),
                        name: key,
                        attributeName: key, // sampleAttributes.attributeName,
                        type: type, // 'NORMAL',
                        displayName: AppService.convertName(key),
                        leafItemSettings: {
                            ...leafItemSettings,
                            colorMapping: attributes[key].map((data, ind) => ({
                                value: data.name.toString(),
                                color: (data.name.toString() === 'NA') ?
                                    '#E6E6E6' :
                                    processedColorCodes(
                                        attributeList,
                                        attributes[key],
                                        countListIndexes[index],
                                        ind
                                    )
                            }))
                        },
                        dataSource: []
                    };
                    attributes[key].forEach((data, ind) => {
                        dataObject.dataSource = [
                            ...dataObject.dataSource,
                            {
                                name: data.name.toString(),
                                count: data.count.toString(),
                                displayName: AppService.firstLetterCase(data.name.toString())
                            }
                        ];
                    });
                    sampleAttributeArrayList = [...sampleAttributeArrayList, dataObject];
                }
            });
            // orderedAttributes = reorderNewSampleAttributes(sampleAttributeArrayList);
            // orderedAttributes = processedColorCodes(orderedAttributes);
        }
        return sampleAttributeArrayList;
    };

    const processedColorCodes = (
        attributeList = [],
        attributeKeyList = [],
        mainIndex,
        dataIndex
    ) => {
        const code = colorCodes[mainIndex + dataIndex] ? colorCodes[mainIndex + dataIndex] : AppService.randomColor();
        return `${code}`;
    }

    const attributeListProcess = (sampleAttributes, index) => {
        const dataObject = {
            id: index,
            generatedId: AppService.getRandomId(7),
            name: sampleAttributes.name,
            attributeName: sampleAttributes.attributeName,
            type: 'OPTIONAL',
            displayName: AppService.convertName(sampleAttributes.name),
            leafItemSettings: {
                ...leafItemSettings,
                colorMapping: sampleAttributes['value'].map((data, ind) => ({
                    value: data.name.toString(),
                    color: (
                        sampleAttributes['value'].length > 1 && colorCodes[ind]
                    ) ? colorCodes[ind] : (
                        sampleAttributes['value'].length === 1
                    ) ? colorCodes[index] : AppService.randomColor()
                }))
            },
            dataSource: []
        };
        sampleAttributes['value'].forEach(data => {
            dataObject.dataSource = [
                ...dataObject.dataSource,
                {
                    name: data.name.toString(),
                    count: data.count.toString()
                }
            ];
        });
        return { ...dataObject };
    }

    const eventSelected = (event) => {
        if (event && event.attribute && event.groupName && canClick) {
            const gpName = Number(event.groupName) ? Number(event.groupName) : event.groupName;
            dispatch(setIsSampleBlockClicked(true));
            setSelectedSampleAttributeSet([event.attribute, gpName]);
        }
    }

    const deleteSampleAttribute = (attribute) => {
        setLoader(true);
        let sampleAttributeArrayList = [];
        sampleAttributeList.forEach((object) => {
            if (attribute.generatedId !== object.generatedId) {
                sampleAttributeArrayList = [...sampleAttributeArrayList, object]; // []; // [...sampleAttributeList];
            }
        });
        setTimeout(() => {
            sampleAttributeArrayList.map((obj, i) => (obj['id'] = i + 1));
            setSampleAttributeState(sampleAttributeArrayList);
            setSampleAttributeList(sampleAttributeArrayList);
            onDeleteItem();
            setLoader(false);
        }, 500);
    }

    const viewSingleSampleBlock = (attribute) => {
        viewSingleBlock({
            type: 'single-sample-block',
            modalData: attribute,
            action: 'close',
            data: {
                title: attribute.displayName,
                btnText: 'CLOSE'
            }
        });
    }

    const treeComponent = () => {
        return (
            sampleAttributeList.length ? sampleAttributeList.map(attribute => {
                return (
                    <div className="tree-map-view-cell" key={attribute.generatedId}>
                        <div className="tree-map-view-cell-title" title={attribute.displayName}>
                            {
                                canScaleUp ?
                                    <div className='scale' onClick={() => {
                                        viewSingleSampleBlock(attribute)
                                    }}></div> :
                                    null
                            }
                            <div className={`text${canScaleUp ? ' shrink' : ''}`}>
                                {attribute.displayName}
                            </div>
                        </div>
                        <TreeMapComponent
                            id={attribute.generatedId}
                            tabIndex={attribute.id}
                            dataSource={attribute.dataSource}
                            weightValuePath={weightValuePath}
                            equalColorValuePath={equalColorValuePath}
                            leafItemSettings={attribute.leafItemSettings}
                            className='treemap'
                            itemClick={(event) => eventSelected({
                                ...event,
                                attribute: attribute.attributeName ? attribute.attributeName : attribute.name
                            })}
                            tooltipSettings={{
                                visible: true,
                                format: toolTipFormat.trim(),
                                template: '<div class="ttip">${displayName} (n=${count})</div>'
                            }}
                            width={dimensions}
                            height={dimensions}>
                            <Inject services={[TreeMapTooltip]} />
                        </TreeMapComponent>
                        {
                            (canClick && canDelete) ?
                                <div className="tree-map-view-cell-action" onClick={() => { deleteSampleAttribute(attribute) }}>
                                    <div className="del"></div>
                                    <div>Delete</div>
                                </div> :
                                null
                        }
                    </div>
                )
            }) : <></>
        );
    }

    return (
        <div className={`tree-map-view${className ? ` ${className}` : ''}`}>
            {treeComponent()}
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    selectedSampleAttributeSet: getSelectedSampleAttributeValues
});

const mapDispatchToProps = (dispatch) => ({
    setLoader: (val) => dispatch(setLoader(val)),
    setSelectedSampleAttributeSet: (val) => dispatch(setSelectedSampleAttributeSet(val)),
    setSampleAttributeState: (val) => dispatch(setSampleAttributeState(val)),
    viewSingleBlock: (val) => dispatch(setModalData(val))
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(SampleAttributesCohort));


{/* <div id="chart">
    <ReactApexChart
        ref={divRef}
        options={attribute.options}
        series={attribute.series}
        type="treemap"
        width={200}
        height={200}
    />
</div> */}